import {Injectable} from '@angular/core';
import Pusher from 'pusher-js';
import PusherJS from 'pusher-js';
import {environment} from '../../../environments/environment';
import Echo from 'laravel-echo';

declare var window: any;

window.Pusher = Pusher;

@Injectable({
  providedIn: 'root'
})

export class EchoService {

  public headers: any;
  private echo!: Echo<any>;

  public execute(): Echo<any> | undefined {
    if (!this.echo) {
      PusherJS.log = function (msg) {
        console.warn(msg);
      };
      return new Echo({
        broadcaster: 'reverb',
        key: '723981b1b4b3b3f7b3b3',
        wsHost: window.location.hostname,
        forceTLS: false,
        enabledTransports: ['ws','wss'],
        authEndpoint: environment.apiUrl + '/auth/pusherjs-login'
      });
    }

    return this.echo;
  }
}
