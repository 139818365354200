import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {EditarCarpetaService} from "./editar-carpeta.service";
import {ValidationService} from "../../../../infrastructure/services/validation.service";
import {Carpeta} from "../../interfaces/carpeta";
import {ModalService} from "../modal/modal.service";

export interface EditarCarpetaFG {
  descripcion: string | null;
}
@Component({
  selector: 'app-modal-editar-carpeta',
  templateUrl: './modal-editar-carpeta.component.html',
  styleUrls: ['./modal-editar-carpeta.component.css']
})
export class ModalEditarCarpetaComponent implements OnInit {

  public editarCarpetaForm = {} as FormGroup;
  public _carpeta = {} as Carpeta;
  public loading: boolean = false;

  @Input() set carpeta(value: Carpeta) {
    if (value) {
      this._carpeta = value;
      this.editarCarpetaForm.patchValue({
        descripcion: this._carpeta.descripcion,
      });
    }
  }

  constructor(private formBuilder: FormBuilder,
              private editarCarpetaService: EditarCarpetaService,
              private validationService: ValidationService,
              private modalService: ModalService,
              ) {
    this.buildForm();
  }

  ngOnInit() {
    this.modalService.setPrimaryButtonAction(this.editarCarpeta.bind(this));
  }

  private buildForm(): void {
    this.editarCarpetaForm = this.formBuilder.group<EditarCarpetaFG>({
      descripcion: null,
    });
  }

  editarCarpeta(): void {
    this.loading = true;
    this.editarCarpetaService.__invoke(this._carpeta.id, this.editarCarpetaForm.value).subscribe({
      next: (carpeta) => {
        this.buildForm();
        this.modalService.close();
        this.loading = false;
      }, error: (errorResponse) => {
        this.loading = false;
        this.validationService.validateForm(this.editarCarpetaForm, errorResponse.error.errors);
      }
    })
  }

  dismiss() {
    this.modalService.dismiss();
  }
}
