<div class="relative" style="z-index: 99 !important;">
  <div class="fixed inset-0 bg-indigo-100 transition-opacity"></div>
  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="lg:flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div
        class="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left sm:my-8 w-full sm:max-w-lg sm:p-6 shadow-xl transition-all ">
        <div class="sm:flex sm:items-start pb-1">
          <div class="mt-3 text-center sm:mt-0 sm:text-left">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Configuración inicial</h3>
          </div>
        </div>
        <div class="grid grid-cols-1 mt-2 sm:mt-0" [formGroup]="configuracionInicialForm">
          <div class="relative mt-2">
            <div class="my-2">
              <label for="tenantId"
                     class="block text-sm font-medium leading-6 text-gray-900">{{ 'Id cliente Azure' | translate }}</label>
              <input formControlName="tenantId" id="tenantId"
                     placeholder="Ej: 12345678-1234-1234-1234-123456789abc"
                     [ngClass]="configuracionInicialForm.get('tenantId')!.errors ? 'ring-1 ring-inset ring-red-300' : 'ring-1 ring-inset ring-gray-300'"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <p class="mt-2 text-sm text-red-600" *ngIf="configuracionInicialForm.get('tenantId')!.errors">
                {{ configuracionInicialForm.get('tenantId')!.errors?.[0] }}
              </p>
            </div>
            <div class="my-2">
              <label for="clientId"
                     class="block text-sm font-medium leading-6 text-gray-900">{{ 'Id App Autenticación Azure' | translate }}</label>
              <input formControlName="clientId" id="clientId"
                     placeholder="Ej: 87654321-4321-4321-4321-cba987654321"
                     [ngClass]="configuracionInicialForm.get('clientId')!.errors ? 'ring-1 ring-inset ring-red-300' : 'ring-1 ring-inset ring-gray-300'"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <p class="mt-2 text-sm text-red-600" *ngIf="configuracionInicialForm.get('clientId')!.errors">
                {{ configuracionInicialForm.get('clientId')!.errors?.[0] }}
              </p>
            </div>
            <div class="my-2">
              <label for="authority"
                     class="block text-sm font-medium leading-6 text-gray-900">{{ 'Url de autenticación' | translate }}</label>
              <input formControlName="authority" id="authority"
                     placeholder="Ej: https://login.microsoftonline.com/common"
                     [ngClass]="configuracionInicialForm.get('authority')!.errors ? 'ring-1 ring-inset ring-red-300' : 'ring-1 ring-inset ring-gray-300'"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <p class="mt-2 text-sm text-red-600" *ngIf="configuracionInicialForm.get('authority')!.errors">
                {{ configuracionInicialForm.get('authority')!.errors?.[0] }}
              </p>
            </div>
            <div class="my-2">
              <label for="temperatura"
                     class="block text-sm font-medium leading-6 text-gray-900">{{ 'Temperatura' | translate }}</label>
              <input formControlName="temperatura" id="temperatura"
                     placeholder="Entre 0.0 y 1.0"
                     [ngClass]="configuracionInicialForm.get('temperatura')!.errors ? 'ring-1 ring-inset ring-red-300' : 'ring-1 ring-inset ring-gray-300'"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <p class="mt-2 text-sm text-red-600" *ngIf="configuracionInicialForm.get('temperatura')!.errors">
                {{ configuracionInicialForm.get('temperatura')!.errors?.[0] }}
              </p>
            </div>
            <div class="mb-2 mt-3">
              <label
                class="block text-sm font-medium leading-6 text-gray-900">{{ 'Grupo por defecto' | translate }}</label>
              <app-custom-select [elementos]="gruposUsuarios"
                                 opcionVacia="Selecciona un grupo"
                                 etiqueta="nombre"
                                 orderBy="nombre"
                                 propiedad="id"
                                 [mostrarOpcionVacia]="false"
                                 formControlName="grupoUsuarioId">
              </app-custom-select>
              <p class="mt-2 text-sm text-red-600" *ngIf="configuracionInicialForm.get('grupoUsuarioId')!.errors">
                {{ configuracionInicialForm.get('grupoUsuarioId')!.errors?.[0] }}
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="guardarConfiguracionInicial()"
                  appLoadingButton [loading]="configurando"
                  class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto">
            {{ 'Guardar' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
