import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {ObtenerGruposUsuariosService} from '../../../application/public/services/obtener-grupos-usuarios.service';
import {GrupoUsuarios} from '../../../application/shared/interfaces/grupo-usuarios';
import {CrearConfiguracionInicialService} from './crear-configuracion-inicial.service';
import {ValidationService} from '../../../infrastructure/services/validation.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

export interface ConfiguracionInicialFG {
  clientId: string | undefined;
  tenantId: string | undefined;
  authority: string | undefined;
  temperatura: number | undefined;
  grupoUsuarioId: string | undefined;
}

@Component({
  selector: 'app-wizard-configuracion',
  templateUrl: './wizard-configuracion.component.html',
  styleUrl: './wizard-configuracion.component.css'
})

export class WizardConfiguracionComponent {
  public configuracionInicialForm: FormGroup = {} as FormGroup;
  public configurando: boolean = false;
  protected gruposUsuarios!: GrupoUsuarios[];

  public constructor(private formBuilder: FormBuilder,
                     private crearConfiguracionInicialService: CrearConfiguracionInicialService,
                     private validationService: ValidationService,
                     private toastrService: ToastrService,
                     private obtenerGruposUsuariosService: ObtenerGruposUsuariosService) {
  }

  public ngOnInit(): void {
    this.buildForm()
    this.obtenerGruposUsuarios();
  }

  public guardarConfiguracionInicial(): void {
    this.crearConfiguracionInicialService.__invoke(this.configuracionInicialForm.value).subscribe({
      next: async () => {
        this.toastrService.success('Configuración inicial guardada correctamente');
        window.location.reload();
      },
      error: (errorResponse) => {
        this.validationService.validateForm(this.configuracionInicialForm, errorResponse.error.errors);
      }
    });
  }

  private buildForm(): void {
    this.configuracionInicialForm = this.formBuilder.group<ConfiguracionInicialFG>({
      clientId: undefined,
      tenantId: undefined,
      authority: undefined,
      temperatura: undefined,
      grupoUsuarioId: undefined,
    });
  }

  private obtenerGruposUsuarios() {
    this.obtenerGruposUsuariosService.__invoke().subscribe({
      next: (gruposUsuarios) => {
        this.gruposUsuarios = gruposUsuarios;
      },
      error: (error) => {
        this.gruposUsuarios = [];
      }
    });
  }
}
