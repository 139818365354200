<div class="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-72 lg:flex-col pt-3">
  <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
    <div class="flex h-auto shrink-0 items-center justify-center">
      <img class="c-pointer w-[25%] mr-3" [src]="logoCuadrado" alt="Logo cuadrado" *ngIf="!soloLogoBase"
           (click)="volverAlHome()">
      <img class="c-pointer" [src]="logoBase" alt="Logo horizontal"
           [ngClass]="{'w-[70%]': !soloLogoBase, 'w-[80%]': soloLogoBase}"
           (click)="volverAlHome()">
    </div>
    <nav class="flex flex-1 flex-col">
      <ul role="list" class="flex flex-1 flex-col gap-y-7">
        <li>
          <app-conversaciones #conversacionWeb></app-conversaciones>
        </li>
      </ul>
    </nav>
  </div>
</div>

<div class="hidden fixed inset-0 bg-gray-900/80 z-10" id="overlay"></div>

<div class="transition hidden fixed inset-0 flex ease-in-out delay-150 z-10 mt-16" id="conversaciones">
  <div class="relative mr-16 flex w-full max-w-xs flex-1">
    <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
      <button type="button" class="-m-2.5 p-2.5" (click)="toogleConversaciones()">
        <span class="sr-only">Close sidebar</span>
        <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
    </div>
    <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
      <nav class="flex flex-1 flex-col mt-5">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <app-conversaciones (chatSeleccionado)="toogleConversaciones()"
                                [esDispositivoMovil]="true"
                                [carpetas]="conversacionWeb.carpetas ? conversacionWeb.carpetas : []"
                                [conversaciones]="conversacionWeb.conversaciones ? conversacionWeb.conversaciones : []">
            </app-conversaciones>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
<div class="lg:pl-72 h-full w-full">
  <div class="top-0 sticky z-10">
    <div
      class="flex h-16 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 lg:shadow-none">
      <img class="h-8 w-auto c-pointer lg:hidden" [src]="logoCuadrado" alt="Logo cuadrado" *ngIf="!soloLogoBase"
           (click)="volverAlHome()">
      <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" (click)="toogleConversaciones()">
        <span class="sr-only">Open sidebar</span>
        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
        </svg>
      </button>

      <!-- Separator -->
      <div class="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true"></div>
      <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 justify-between">
        <div class="lg:flex lg:gap-x-12 gap-x-4 flex items-center mx-5">
          <ng-container *ngFor="let elementoMenu of elementosMenu">
            <a [routerLink]="elementoMenu.ruta" class="text-sm font-semibold leading-6 text-indigo-600 w-full"
               [ngClass]="rutaVisible(elementoMenu) ? 'border-b-2 border-indigo-300' : 'border-b-0  border-transparent'">
              {{ elementoMenu.descripcion }}
            </a>
          </ng-container>
        </div>
        <div class="flex items-center gap-x-4 lg:gap-x-6">
          <div class="relative inline-block">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                 *ngIf="mostrarTooltip && rutaActualTieneAyuda()"></div>
            <div class="flex flex-col" *ngIf="mostrarTooltip && rutaActualTieneAyuda()">
              <div
                class="mt-5 px-4 py-4 text-sm absolute top-full left-1/2 transform -translate-x-1/2 w-[30rem] bg-white text-gray-800 border border-gray-300 rounded-lg shadow-lg tooltip-container z-20">
                <app-tooltip [text]="'Accede a las guías de uso según la pantalla en la que te encuentras.'"
                             [title]="'Acceso manual de uso'"
                             [mostrarBoton]="true"
                             (dismissed)="marcarTooltip($event)"></app-tooltip>
              </div>
            </div>
            <button *ngIf="rutaActualTieneAyuda()"
                    [ngClass]="mostrarTooltip ? 'z-10' : 'z-0'"
                    class="text-indigo-600 text-lg bg-white focus:outline-none hover:bg-indigo-100 inline-flex items-center justify-center w-10 h-10 rounded-full p-3 leading-6 font-semibold c-pointer border shadow-sm hover:border-indigo-500 relative"
                    (click)="abrirAyuda()">?
            </button>
          </div>
          <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true"></div>
          <div>
            <button type="button" class="-m-1.5 flex items-center p-1.5" id="user-menu-button" aria-expanded="false"
                    aria-haspopup="true" (click)="toogleOpcionesPerfil()">
              <span class="sr-only">Open user menu</span>
              <img class="h-8 w-8 rounded-full bg-gray-50" *ngIf="imagenPerfil" src="{{this.imagenPerfil}}" alt="">
              <span class="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100" *ngIf="!imagenPerfil">
                 <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                   <path
                     d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                  </svg>
                </span>

              <span class="hidden lg:flex lg:items-center">
                  <ng-container *ngIf="this.usuario"><span class="ml-4 text-sm font-semibold leading-6 text-gray-900"
                                                           aria-hidden="true">{{ this.usuario!.name }}</span></ng-container>
                  <svg class="ml-2 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                          clip-rule="evenodd"/>
                  </svg>
                </span>
            </button>
            <div
              class="hidden absolute right-0  mt-2.5 w-52 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
              role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1"
              id="opciones-perfil">
              <a class="block px-3 py-1 text-sm leading-6 text-red-500 c-pointer" role="menuitem" tabindex="-1"
                 id="user-menu-item-1" (click)="logout()">
                {{ 'Cerrar sesión' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <main class="lg:mt-7 mt-2">
    <div class="mx-auto max-w-full px-4 sm:px-6 lg:px-8 w-full h-full ">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
