<ul role="list" class="-mx-2 space-y-1">
  <li class="flex border-b pb-3 justify-between">
    <div (click)="abrirModalNuevoChat()" title="{{'Nuevo chat' | translate}}"
         class="w-4/5 hover:bg-indigo-100 text-indigo-600 group flex gap-x-1 rounded-md p-3 text-sm leading-6 font-semibold c-pointer border shadow-sm hover:border-indigo-500">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
           stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>
      </svg>
      {{'Nuevo chat' | translate}}
    </div>
    <div (click)="abrirModalNuevaCarpeta()" title="{{'Nueva carpeta' | translate}}"
         class="hover:bg-indigo-100 text-indigo-600 group rounded-md p-3 text-sm leading-6 font-semibold c-pointer border shadow-sm  hover:border-indigo-500 ml-2">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
      </svg>

    </div>
  </li>
  <li>
    <div [formGroup]="buscarConversacionesForm">
      <div class="mt-3 mb-6">
        <input type="text" formControlName="asunto" id="asunto"
               placeholder="{{'Buscar conversación...' | translate}}"
               class="block w-full rounded-md border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
      </div>
    </div>
  </li>
  <app-carpetas #carpetasComponent
                [descripcion]="buscarConversacionesForm.get('asunto')?.value"
                [esDispositivoMovil]="_esDispositivoMovil">
  </app-carpetas>
</ul>
