import {Component, Input, OnInit} from '@angular/core';
import {DatosModalConfirmacion} from './datos-modal-confirmacion';
import {ModalService} from "../modal/modal.service";

@Component({
  selector: 'app-modal-confirmacion-accion',
  templateUrl: './modal-confirmacion-accion.component.html',
  styleUrls: ['./modal-confirmacion-accion.component.css']
})
export class ModalConfirmacionAccionComponent implements OnInit {
  public _actionConfirmationData = {} as DatosModalConfirmacion;

  @Input() set actionConfirmationData(actionConfirmationData: DatosModalConfirmacion) {
    this._actionConfirmationData = actionConfirmationData;
  }

  constructor(private modalService:ModalService) {
  }

  ngOnInit(): void {
    this.modalService.setPrimaryButtonAction(this.confirm.bind(this));
  }

  confirm() {
    this.modalService.close(true);
  }

  dismiss() {
    this.modalService.dismiss()
  }
}
