import {Component, Input, OnInit} from '@angular/core';
import {CrearConversacionService} from './crear-conversacion.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ValidationService} from '../../../../infrastructure/services/validation.service';
import {PerfilConversacionAsignado, PerfilConversacionListable} from '../../interfaces/perfil-conversacion';
import {Carpeta} from "../../interfaces/carpeta";
import {ListarCarpetasService} from '../../services/listar-carpetas.service';
import {zip} from "rxjs";
import {ObtenerPerfilesConversacionService} from '../../services/obtener-perfiles-conversacion.service';
import {ObtenerGrupoAsignadoService} from "../../services/obtener-grupo-asignado.service";
import {
  ObtenerPerfilesAsignadosGrupoUsuariosService
} from '../../services/obtener-perfiles-asignados-grupo-usuarios.service';
import {ModalService} from '../modal/modal.service';
import {OptionModelo} from "../../interfaces/option-item";

export interface CrearConversacionFG {
  asunto: string | null;
  mensaje_sistema: string | null;
  perfil_conversacion_id: string | null;
  modelo: string | null;
  carpeta_id: string | null;
  perfiles_limitados: boolean;
}

@Component({
  selector: 'app-modal-crear-conversacion',
  templateUrl: './modal-crear-conversacion.component.html',
  styleUrls: ['./modal-crear-conversacion.component.css']
})
export class ModalCrearConversacionComponent implements OnInit {
  public crearConversacionForm = {} as FormGroup;
  public loading: boolean = false;
  public modelosLLMActivos!: OptionModelo[];
  public perfilConversacionIdAsignadoPorGrupo: string | null | undefined;

  @Input()
  set modelosActivos(modelosActivos: OptionModelo[] | undefined) {
    if (modelosActivos) {
      this.modelosLLMActivos = modelosActivos;
      if (this.modelosLLMActivos.length == 0) {
        this.crearConversacionForm.get('asunto')?.disable();
      }
    }
  }

  public carpetas!: Carpeta[];
  public perfilesConversacion!: PerfilConversacionAsignado[] | PerfilConversacionListable[];
  public permiteOpcionPersonalizada: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private crearConversacionService: CrearConversacionService,
              private validationService: ValidationService,
              private obtenerGrupoAsignadoService: ObtenerGrupoAsignadoService,
              private listarCarpetasService: ListarCarpetasService,
              private obtenerPerfilesConversacionService: ObtenerPerfilesConversacionService,
              private obtenerPerfilesAsignadosGrupoUsuariosService: ObtenerPerfilesAsignadosGrupoUsuariosService,
              private modalService: ModalService) {
    this.buildForm();
  }

  public ngOnInit(): void {
    this.modalService.setPrimaryButtonAction(this.crearChat.bind(this));
    this.inicializar();
  }

  private buildForm(): void {
    this.crearConversacionForm = this.formBuilder.group<CrearConversacionFG>({
      asunto: null,
      mensaje_sistema: null,
      perfil_conversacion_id: null,
      modelo: null,
      carpeta_id: null,
      perfiles_limitados: false
    });
  }

  public dismiss(): void {
    this.modalService.dismiss();
  }


  public crearChat(): void {
    this.loading = true;
    this.crearConversacionService.__invoke(this.crearConversacionForm.value).subscribe({
      next: (conversacion) => {
        this.buildForm();
        this.modalService.close(conversacion);
        this.loading = false;
      }, error: (errorResponse) => {
        this.loading = false;
        this.validationService.validateForm(this.crearConversacionForm, errorResponse.error.errors);
      }
    });
  }

  inicializar() {
    if (this.modelosLLMActivos && this.modelosLLMActivos.length == 1) {
      this.crearConversacionForm.get('modelo')?.setValue(this.modelosLLMActivos[0].nombre);
    }

    this.obtenerGrupoAsignadoService.__invoke().subscribe({
      next: (grupo) => {
        this.obtenerPerfilesAsignadosGrupoUsuariosService.__invoke(grupo.id).subscribe({
          next: (perfiles) => {
            this.perfilesConversacion = perfiles;
            if (perfiles && perfiles.length === 0) {
              this.permiteOpcionPersonalizada = true;
              this.obtenerPerfilesConversacionService.__invoke().subscribe({
                next: (perfilesConversacion) => {
                  this.perfilesConversacion = perfilesConversacion;
                }
              });
            } else {
              this.permiteOpcionPersonalizada = false;
              this.crearConversacionForm.get('perfiles_limitados')?.setValue(true)
            }

            if (this.perfilesConversacion && this.perfilesConversacion.length === 1) {
              this.crearConversacionForm.patchValue({perfil_conversacion_id: this.perfilesConversacion[0].id})
              this.perfilConversacionIdAsignadoPorGrupo = this.perfilesConversacion[0].id;
            }
            this.obtenerDatosSelects();
          }
        });
      }
    });
  }

  private obtenerDatosSelects(): void {
    zip(
      this.listarCarpetasService.__invoke(),
    ).subscribe({
      next: ([carpetas]) => {
        this.carpetas = carpetas;
      }
    });
  }
}
