import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ValidationService} from "../../../../infrastructure/services/validation.service";
import {CrearCarpetaService} from "./crear-carpeta.service";
import {ModalService} from "../modal/modal.service";

export interface CrearCarpetaFG {
  descripcion: string | null;
}
@Component({
  selector: 'app-modal-crear-carpeta',
  templateUrl: './modal-crear-carpeta.component.html',
  styleUrls: ['./modal-crear-carpeta.component.css']
})
export class ModalCrearCarpetaComponent implements OnInit {

  public crearCarpetaForm = {} as FormGroup;
  public loading: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private validationService: ValidationService,
              private crearCarpetaService: CrearCarpetaService,
              private modalService: ModalService,
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this.modalService.setPrimaryButtonAction(this.crearCarpeta.bind(this));
  }

  private buildForm(): void {
    this.crearCarpetaForm = this.formBuilder.group<CrearCarpetaFG>({
      descripcion: null,
    });
  }

  crearCarpeta(): void {
    this.loading = true;
    this.crearCarpetaService.__invoke(this.crearCarpetaForm.value).subscribe({
      next: (carpeta) => {
        this.buildForm();
        this.modalService.close(true);
        this.loading = false;
      }, error: (errorResponse) => {
        this.loading = false;
        this.validationService.validateForm(this.crearCarpetaForm, errorResponse.error.errors);
      }
    })
  }

  dismiss() {
    this.modalService.dismiss();
  }
}
