import {APP_INITIALIZER, NgModule} from '@angular/core';
import {ObtenerConfiguracionMsalService} from '../../services/obtener-configuracion-msal.service';
import {InteractionType, LogLevel, PublicClientApplication} from '@azure/msal-browser';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService
} from '@azure/msal-angular';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {CustomInterceptorInterceptor} from '../../../../infrastructure/interceptor/custom-interceptor.interceptor';
import {ApiKeyInterceptor} from "../../../../infrastructure/interceptor/api-key.interceptor";


let config: any;

export function inicializarConfiguracionMsalFactory(obtenerConfiguracionMsalService: ObtenerConfiguracionMsalService): any {
  const promise = obtenerConfiguracionMsalService.__invoke().then((configuracion) => {
  });
  return () => promise;
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.info(message);
}

export function MSALInstanceFactory(obtenerConfiguracionMsalService: ObtenerConfiguracionMsalService): PublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: obtenerConfiguracionMsalService.configuracionMSAL.msal.clientId,
      authority: obtenerConfiguracionMsalService.configuracionMSAL.msal.authority,
      redirectUri: obtenerConfiguracionMsalService.configuracionMSAL.msal.redirectUri,
      postLogoutRedirectUri: obtenerConfiguracionMsalService.configuracionMSAL.msal.postLogoutRedirectUri,
    },
    cache: {
      cacheLocation: 'sessionStorage'
    },
    system: {
      tokenRenewalOffsetSeconds: 600,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: true
      }
    }
  });
}

export function MSALInterceptorConfigFactory(obtenerConfiguracionMsalService: ObtenerConfiguracionMsalService): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>(obtenerConfiguracionMsalService.obtenerValor('interceptor'));
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  }
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {} as MsalGuardConfiguration;
}

@NgModule({
  declarations: [],
  imports: [MsalModule]
})
export class ConfiguracionMsalModule {
  static forRoot() {
    return {
      ngModule: ConfiguracionMsalModule,
      providers: [
        ObtenerConfiguracionMsalService,
        {
          provide: APP_INITIALIZER,
          useFactory: inicializarConfiguracionMsalFactory,
          deps: [ObtenerConfiguracionMsalService],
          multi: true
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [ObtenerConfiguracionMsalService]
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
          deps: []
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [ObtenerConfiguracionMsalService]
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        [
          {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiKeyInterceptor,
            multi: true
          },
          {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomInterceptorInterceptor,
            multi: true
          },
          {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
          },
        ]
      ]
    }
  }
}
