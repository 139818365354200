import {inject, Injectable} from '@angular/core';
import {FormArray, FormGroup, UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable()
export class ValidationService {

  constructor(private domSanitizer: DomSanitizer) {
  }

  public validateForm(formGroup: UntypedFormGroup, validationMessages: any): void {
    for (const validationMessage in validationMessages) {
      const splitedMessage = validationMessage.split('.');
      if (splitedMessage.length > 1) {
        this.getValidationControlFromArray(formGroup, validationMessage)!.setErrors(validationMessages[validationMessage]);
      } else {
        if (formGroup.controls[validationMessage]) {
          formGroup.controls[validationMessage].setErrors(validationMessages[validationMessage]);
        }
      }
    }
  }

  public validateFormForToastr(formGroup: UntypedFormGroup, validationMessages: any): string {
    let errores = '<div>';
    for (const validationMessage in validationMessages) {
      const splitedMessage = validationMessage.split('.');
      if (splitedMessage.length > 1) {
        errores += '<div>' + validationMessages[validationMessage] + '</div></br>';
      } else {
        if (formGroup.controls[validationMessage]) {
          errores += '<div>' + validationMessages[validationMessage] + '</div></br>';
        }
      }
    }
    errores += '</div>';
    return this.domSanitizer.bypassSecurityTrustHtml(errores) as string;
  }

  private getValidationControlFromArray(formGroup: UntypedFormGroup, validationMessage: string): FormGroup<any> | FormArray<any> | undefined {
    const splitedMessage = validationMessage.split('.');
    const abstractControl = formGroup.get(splitedMessage[0]) as UntypedFormArray;
    if (abstractControl instanceof UntypedFormGroup) {
      return (abstractControl).get(splitedMessage[1]) as UntypedFormGroup;
    }
    if (abstractControl instanceof UntypedFormArray) {
      return (abstractControl.at(Number(splitedMessage[1]))).get(splitedMessage[2]) as UntypedFormArray;
    }
    return undefined;
  }

  public obtenerMensajesDeValidacion(validationMessages: any): string[] {
    const mensajesDeValidacion: string[] = [];
    for (const validationMessage in validationMessages) {
      const splitedMessage = validationMessage.split('.');
      if (splitedMessage.length > 1) {
        mensajesDeValidacion.push(validationMessages[validationMessage]);
      } else {
        mensajesDeValidacion.push(validationMessages[validationMessage]);
      }
    }
    return mensajesDeValidacion;
  }
}
