import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {ConfiguracionInicial} from './configuracion-inicial';

@Injectable({
  providedIn: 'root'
})
export class CrearConfiguracionInicialService {

  private readonly url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.apiUrl + '/guest/configuracion-inicial';
  }

  public __invoke(data: any): Observable<ConfiguracionInicial> {
    return this.httpClient.post<ConfiguracionInicial>(this.url, data);
  }
}
