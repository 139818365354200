import {APP_INITIALIZER, ErrorHandler, inject, LOCALE_ID, NgModule, SecurityContext} from '@angular/core';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import localeEs from '@angular/common/locales/es';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PantallaCompletaComponent} from './infrastructure/layout/pantalla-completa/pantalla-completa.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from '../environments/environment';
import {MsalRedirectComponent} from '@azure/msal-angular';
import {PantallaConMenuComponent} from './infrastructure/layout/pantalla-con-menu/pantalla-con-menu.component';
import {BrowserAnimationsModule, provideAnimations} from '@angular/platform-browser/animations';
import {provideToastr, ToastrModule} from 'ngx-toastr';
import {ValidationService} from './infrastructure/services/validation.service';
import {ConversacionesModule} from './application/shared/modules/conversaciones/conversaciones.module';
import {
  CLIPBOARD_OPTIONS,
  ClipboardButtonComponent,
  MarkdownModule,
  MARKED_OPTIONS,
  MarkedOptions,
  MarkedRenderer
} from 'ngx-markdown';
import * as Sentry from "@sentry/angular";
import {Router} from '@angular/router';
import {
  ConfiguracionMsalModule
} from './application/public/dynamic-configurations/configuracion-msal/configuracion-msal.module';
import {
  ImagenCorporativaModule
} from './application/public/dynamic-configurations/imagen-corporativa/imagen-corporativa.module';
import {ForzarRedireccionService} from './infrastructure/services/forzar-redireccion.service';
import {registerLocaleData} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {GrupoBotonesModule} from "./application/shared/modules/grupo-botones/grupo-botones.module";
import {LoadingButtonDirective} from "./infrastructure/directives/loading-button.directive";
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {ModalModule} from './application/shared/modules/modal/modal.module';
import {PanelLateralModule} from './application/shared/modules/panel-lateral/panel-lateral.module';
import {AyudaModule} from './domain/shared/ayuda/ayuda.module';
import {TooltipModule} from "./application/shared/modules/tooltip/tooltip.module";
import {GlobalErrorHandler} from "./infrastructure/global-error-handler";
import {AngularxDatatableModule} from "@lunia/angularx-datatable";
import {EnterPressDirective} from './infrastructure/directives/enter-press.directive';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.apiUrl + '/public/langs/', '');
}

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();
  const sanitizer = inject(DomSanitizer);
  renderer.link = (href, title, text) => {
    let out = `<a href="${href}"`;
    if (title) {
      out += ` title="${title}"`;
    }
    out += ` target="_blank" rel="noopener noreferrer">${text}</a>`;
    return out;
  }

  renderer.table = (header, body) => {
    const tableId = 'table-' + Math.random().toString(36).substring(2, 9);
    const boton = `<div class="flex justify-start my-3">
                        <button disabled onclick="descargarContenidoTabla('${tableId}')" class="boton-descargar-tabla inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>
                            Descargar tabla
                        </button>
                   </div>
                   `;
    const table = `
            <div class="border rounded-lg my-3 overflow-x-scroll">
                <table class="table-auto divide-gray-300 divide-y table-sm striped-table w-full" id="${tableId}">
                    <thead class="font-bold bg-white">${header}</thead>
                    <tbody>${body}<tr></tr></tbody>
                </table>
            </div> ${boton}`;

    return sanitizer.sanitize(SecurityContext.HTML, sanitizer.bypassSecurityTrustHtml(table)) || '';
  };


  renderer.tablerow = (content) => {
    return `<tr class="border-t-1 border-b-1">${content}</tr>`;
  }

  renderer.tablecell = (content, flags) => {
    return `<td class="border-gray-300 px-4 py-1">${content}</td>`;
  }


  return {
    renderer: renderer,
    gfm: true,
    breaks: true,
    pedantic: false,
  };
}


registerLocaleData(localeEs);

// Lista de patrones de error a ignorar
const ignoredErrorPatterns = [
  '0 Unknown Error',
  '401 Unauthorized',
  '403 Forbidden',
  '404 Not Found',
  '500 Internal Server Error',
  '502 Bad Gateway',
  '503 Service Unavailable',
  '504 Gateway Timeout',
  'Http failure during parsing for .*' // Captura cualquier URL
];

// Construcción de la expresión regular combinando los patrones
const serverErrorsRegex = new RegExp(
  ignoredErrorPatterns.join('|'),
  'mi'
);
Sentry.init({
  dsn: environment.sentryDsn,
  release: 'luniagpt-frontend@latest',
  integrations: [],
  ignoreErrors: [serverErrorsRegex],
  tracesSampleRate: 0.0,
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 0.0,
});

@NgModule({
  declarations: [
    AppComponent,
    PantallaCompletaComponent,
    PantallaConMenuComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ConfiguracionMsalModule.forRoot(),
    ImagenCorporativaModule.forRoot(),
    NgxUiLoaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    ConversacionesModule,
    MarkdownModule.forRoot({
        sanitize: SecurityContext.NONE,
        clipboardOptions: {
          provide: CLIPBOARD_OPTIONS,
          useValue: {
            buttonComponent: ClipboardButtonComponent,
          },
        },
        markedOptions: {
          provide: MARKED_OPTIONS,
          useFactory: markedOptionsFactory,
        },
      }
    ),
    FormsModule,
    GrupoBotonesModule,
    LoadingButtonDirective,
    ReactiveFormsModule,
    AngularxDatatableModule,
    ModalModule,
    PanelLateralModule,
    AyudaModule,
    TooltipModule
  ],
  providers: [
    ValidationService,
    ForzarRedireccionService,
    provideAnimations(),
    provideToastr(),
    {
      provide: ErrorHandler,
      useValue: GlobalErrorHandler
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'es-ES'
    }
  ],
  exports: [],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
