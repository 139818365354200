import { Injectable } from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {GrupoUsuarios} from '../../shared/interfaces/grupo-usuarios';

@Injectable({
  providedIn: 'root'
})
export class ObtenerGruposUsuariosService {

  public readonly gruposUsuariosUrl: string;

  constructor(private readonly httpClient: HttpClient) {
    this.gruposUsuariosUrl = environment.apiUrl + '/guest/grupos-usuarios';
  }

  public __invoke(): Observable<GrupoUsuarios[]> {
      return this.httpClient.get<GrupoUsuarios[]>(this.gruposUsuariosUrl);
  }
}
